<template>
  <div class="index">
    <div class="return flex align" @click="toReturn">
      <img src="@/assets/return.png" />
      <div>返回上一页</div>
    </div>
    <img src="@/assets/bg3.png" class="bg" />
  </div>
</template>
<script>
export default {
  methods: {
    toReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .return {
    color: #ff690e;
    font-size: 40px;
    margin: 48px 51px;
    img {
      margin-right: 16px;
    }
  }
  .tit_img {
    text-align: center;
    margin: 88px 0 118px;
    // img {
    //   width: 600px;
    // }
  }
}
</style>